/* HTML Elements */
button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
  background-color: #94a3b8;
  background-image: none;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: thin !important;
  scrollbar-color: #6b7280 #eeeff8 !important;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Feature classes */

.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  -ms-user-select: none;
  user-select: none;
}

.gradient-background {
  background: rgba(235, 0, 139, 0.4);
  background: -moz-linear-gradient(
    45deg,
    rgba(235, 0, 139, 0.4) 35%,
    rgba(9, 211, 201, 0.4) 100%
  ) !important;
  background: -webkit-linear-gradient(
    45deg,
    rgba(235, 0, 139, 0.4) 35%,
    rgba(9, 211, 201, 0.4) 100%
  ) !important;
  background: linear-gradient(
    45deg,
    rgba(235, 0, 139, 0.4) 35%,
    rgba(9, 211, 201, 0.4) 100%
  ) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#eb008b",endColorstr="#09d3c9",GradientType=1) !important;
}
/* Custom scroll */

* {
  scrollbar-width: thin !important;
  scrollbar-color: #6b7280 #eeeff8 !important;
}

::-webkit-scrollbar {
  border-radius: 0.375rem;
  display: block;
  width: 8px;
  height: 0px;
  background-color: #eeeff8;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.375rem;
  background-color: #6b7280;
  border-right: none;
  border-left: none;
}
::-webkit-scrollbar-track-piece {
  background: transparent;
}

/* Ant Design Custom Theme */

.public-dp {
  height: 38px;
}

.public-dp > .ant-picker-input > input {
  font-size: 0.875rem !important;
}

.my-select-container,
.ant-select,
.ant-select-selector {
  border-radius: 0.375rem !important;
}

.ant-pagination-item {
  align-self: center !important;
}

.ant-modal-content,
.ant-modal-header {
  border-radius: 0.375rem !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:active,
.ant-tabs-tab-remove:focus,
.ant-menu-item:hover,
.ant-menu-item-selected {
  color: #f14dae !important;
  border-color: #f14dae !important;
}

.ant-input-number-prefix {
  font-weight: 500 !important;
  margin-right: 11px !important;
}

.ant-tabs-tab-btn,
.ant-input-number-prefix,
.ant-picker-input > input,
.ant-input-number-input-wrap > input {
  color: #6b7280 !important;
}

.ant-form-item-label > label {
  font-weight: 500 !important;
  color: #6b7280 !important;
}

.ant-picker-ranges {
  width: 150px !important;
}

.ant-picker-ok > button {
  color: white !important;
  border-radius: 0.375rem !important;
  background-color: #f14dae !important;
}

/* Video Call classes */

.app {
  min-height: 100vh;
  display: grid;
  grid-template-rows: 4rem 1fr 4rem;
}

p {
  font-size: 16px;
  margin: 16px;
  text-align: center;
}

h1 {
  margin: 0;
  padding: 0;
  font-weight: 500;
  color: turquoise;
}

p.small {
  color: darkgrey;
  font-size: 12px;
}

button {
  background-color: turquoise;
  border: none;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  padding: 12px;
}

video {
  width: 100%;
  height: 100% !important;
  object-fit: cover;
  border-radius: 4px;
}

.api-error {
  background: darkblue;
  width: 480px;
  height: 270px;
  display: inline-flex;
  flex-direction: column;
  align-self: center;
  justify-self: center;
  text-align: center;
  padding: 3rem;
  box-sizing: border-box;
  border-radius: 4px;
}

.api-error p {
  color: white;
}

.api-error h1 {
  color: red;
}

.api-error a,
.api-error a:visited {
  color: white;
}

.home-screen {
  padding: 0 1rem;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  justify-content: center;
}

/* header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid grey;
  padding: 0.5rem 1rem;
  background-color: white;
} */

.span {
  font-size: 12px;
  font-weight: 600;
}

span.title {
  padding: 0 16px;
}

header a {
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  color: darkblue;
}

header a.new-tab-link {
  border: 1px solid darkgrey;
  border-radius: 8px;
}

a:visited {
  color: darkblue;
}

a:focus {
  color: darkblue;
}

a:active {
  color: turquoise;
}

a:hover {
  color: turquoise;
}

.header-section {
  display: flex;
  align-items: center;
}

.call {
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: repeat(auto-fit, minmax(480px, 1fr));
  grid-auto-rows: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  width: 100%;
  box-sizing: border-box;
}

@media only screen and (max-width: 600px) {
  .call {
    padding: 0;
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  }
}

.is-screenshare {
  display: grid;
  padding: 5rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-row-gap: 15px;
}
/* 
.self-view {
  width: 480px;
  height: 270px;
} */
/* 
@media only screen and (max-width: 600px) {
  .self-view {
    width: 240px;
    height: 135px;
  }
} */

/* .self-view.alone {
  width: 480px;
  height: 270px;
} */

/* @media only screen and (max-width: 600px) {
  .self-view.alone {
    width: 240px;
    height: 135px;
  }
} */

.self-view video {
  transform: rotate3d(0, 1, 0, 180deg);
}

/* .is-screenshare .self-view {
  width: 240px;
  height: 135px;
} */

.info-box {
  background: darkgrey;
  color: white;
  width: 480px;
  height: 270px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 3rem;
  box-sizing: border-box;
  border-radius: 4px;
}

@media only screen and (max-width: 600px) {
  .info-box {
    width: auto;
    height: auto;
  }
}

.room-url {
  padding: 0 1rem;
  word-break: break-word;
  font-weight: normal;
  font-size: 12px;
}

.tile-video {
  max-width: 480px;
  max-height: 270px;
}

.is-screenshare .tile-video {
  width: 240px;
  height: 135px;
}

.tile-screenshare {
  max-width: 852px;
  max-height: 480px;
  grid-column: 1 / -1;
  grid-row: 1;
}

.tray {
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
}

.tray button {
  padding: 0 10px;
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-weight: normal;
}

.tray-buttons-container button svg {
  color: #121a24;
}

.tray-buttons-container button + button {
  margin-left: 2rem;
}

.controls,
.actions,
.leave {
  display: flex;
  flex: 1;
  align-items: center;
}

.controls {
  justify-content: flex-start;
}

.actions {
  justify-content: center;
}

.leave {
  justify-content: flex-end;
}

.get-user-media-error {
  grid-column: 1 / 3;
  align-items: center;
}

.get-user-media-error h1 {
  color: red;
}

.get-user-media-error button {
  margin: 1rem 0 1rem 0;
  background: black;
  color: white;
}

.get-user-media-error a,
.get-user-media-error a:visited {
  color: white;
  text-decoration: underline;
}

.get-user-media-error p {
  margin: 0;
}

.meeting-information {
  background-color: white;
  border-radius: 8px;
  font-size: 12px;
  border: 1px solid grey;
  padding: 12px;
  cursor: pointer;
  position: absolute;
  top: 4.5rem;
  left: 0.5rem;
  max-width: 400px;
  z-index: 2;
}

.meeting-information h1 {
  color: gray;
}

/* Custom Animations */

@keyframes bounceAlpha {
  0% {
    opacity: 1;
    transform: translateX(0px) scale(1);
  }
  25% {
    opacity: 0;
    transform: translateX(10px) scale(0.9);
  }
  26% {
    opacity: 0;
    transform: translateX(-10px) scale(0.9);
  }
  55% {
    opacity: 1;
    transform: translateX(0px) scale(1);
  }
}

@keyframes bounceAlphaLeft {
  0% {
    opacity: 1;
    transform: translateX(0px) scale(1);
  }
  25% {
    opacity: 0;
    transform: translateX(-10px) scale(0.9);
  }
  26% {
    opacity: 0;
    transform: translateX(10px) scale(0.9);
  }
  55% {
    opacity: 1;
    transform: translateX(0px) scale(1);
  }
}

@keyframes bounceAlphaDown {
  0% {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
  25% {
    opacity: 0;
    transform: translateY(10px) scale(0.9);
  }
  26% {
    opacity: 0;
    transform: translateY(-10px) scale(0.9);
  }
  55% {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
}

.bounceAlpha {
  animation-name: bounceAlpha;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.bounceAlphaLeft {
  animation-name: bounceAlphaLeft;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.bounceAlphaDown {
  animation-name: bounceAlphaDown;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.ant-upload-list-item {
  width: 16vw !important;
}
.ant-upload-list-item-card-actions-btn {
  opacity: 1 !important;
}
.ant-btn-sm.ant-btn-primary {
  background-color: rgb(241, 77, 174) !important;
}
.ant-btn-sm.ant-btn-primary:hover {
  background-color: #ff7ac3 !important;
}
